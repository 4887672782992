<template>
  <v-expansion-panel v-if="false">
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'ApuracaoAcaoDetalhesInforAcaoPrevia',
  props: {
    acao: {
      type: Object,
      default: {},
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
};
</script>
