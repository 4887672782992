<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      {{ $tc('title.detalhe_acao_planejamento_acao', 1)}}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <detalhes-acao
        v-if="isDetalheAcaoTemplate && indCarregado"
        ref="detalheAcao"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="conf"
        :isApuracao="true"
        :editaApuracao="podeEditarApuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="true"/>
      <planejamento-acao-form-detalhes-acao-customizada
        v-if="isTudoDeCorTemplate && indCarregado"
        ref="detalheAcao"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="conf"
        :isApuracao="true"
        :editaApuracao="podeEditarApuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-doacao-direta
        ref="detalheAcao"
        v-if="isDoacaoDiretaLogisticaTemplate && indCarregado"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="conf"
        :isApuracao="true"
        :editaApuracao="podeEditarApuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-doacao-direta-galpao
        ref="detalheAcao"
        v-if="isDoacaoDiretaGalpaoTemplate && indCarregado"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="conf"
        :isApuracao="true"
        :editaApuracao="podeEditarApuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
      <planejamento-acao-doacao-indireta
        ref="detalheAcao"
        v-if="isDoacaoIndiretaTemplate && indCarregado"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="conf"
        :isApuracao="true"
        :editaApuracao="podeEditarApuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
        <planejamento-acao-servicos-terceiros
        ref="detalheAcao"
        v-if="isDetalheAcaoTemplateServicosTerceiros && indCarregado"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="conf"
        :isApuracao="true"
        :editaApuracao="podeEditarApuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
        <planejamento-acao-comunicacao
        ref="detalheAcao"
        v-if="isDetalheAcaoTemplateComunicacao && indCarregado"
        :acao="acao"
        :nova-acao="novaAcao"
        :tipoAcao="tipoAcao"
        :configuracao="conf"
        :isApuracao="true"
        :editaApuracao="podeEditarApuracao"
        @PlanejamentoAcaoFormDetalhesAcao__AlteraValorAcao="alteraValorAcao"
        :somenteLeitura="somenteLeitura"/>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>

import { mapGetters } from 'vuex';
import PlanejamentoAcaoFormDetalhesAcaoCustomizada from '../planejamento-acao/form/PlanejamentoAcaoFormDetalhesAcaoCustomizada';
import AcaoConfiguracao from '../../produto/spa/planejamento-acao/AcaoConfiguracao';
import DetalhesAcao from '../planejamento-acao/form/detalhe-acao/DetalhesAcao';
import PlanejamentoAcaoDoacaoDireta from '../planejamento-acao/form/detalhe-acao/PlanejamentoAcaoDoacaoDireta';
import PlanejamentoAcaoDoacaoDiretaGalpao from '../planejamento-acao/form/detalhe-acao/PlanejamentoAcaoDoacaoDiretaGalpao';
import PlanejamentoAcaoDoacaoIndireta from '../planejamento-acao/form/detalhe-acao/PlanejamentoAcaoDoacaoIndireta';
import PlanejamentoAcaoServicosTerceiros from '../planejamento-acao/form/detalhe-acao/PlanejamentoAcaoServicosTerceiros';
import PlanejamentoAcaoComunicacao from '../planejamento-acao/form/detalhe-acao/PlanejamentoAcaoComunicacao';

export default {
  name: 'ApuracaoDetalhesAcao',
  components: {
    PlanejamentoAcaoFormDetalhesAcaoCustomizada,
    AcaoConfiguracao,
    DetalhesAcao,
    PlanejamentoAcaoDoacaoDireta,
    PlanejamentoAcaoDoacaoDiretaGalpao,
    PlanejamentoAcaoDoacaoIndireta,
    PlanejamentoAcaoServicosTerceiros,
    PlanejamentoAcaoComunicacao,
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),

      tipoAcao: null,
      conf: null,
      indConfiguracao: false,
    };
  },
  props: {
    acao: {
      type: Object,
      default: {},
    },
    somenteLeitura: Boolean,
    novaAcao: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    edicao: Boolean,
    novo: Boolean,
    editaApuracao: Boolean,
  },
  watch: {
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeEditar() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'APU_ACAO_CRUD').length;
    },
    isDetalheAcaoTemplate() {
      return this.configuracao.passo3.template === 'DETALHE_ACAO';
    },
    isTudoDeCorTemplate() {
      return this.configuracao.passo3.template === 'SUST_TUDO_DE_COR';
    },
    isDoacaoDiretaLogisticaTemplate() {
      return this.configuracao.passo3.template === 'SUST_LOGISTICA';
    },
    isDoacaoDiretaGalpaoTemplate() {
      return this.configuracao.passo3.template === 'SUST_GALPAO';
    },
    isDoacaoIndiretaTemplate() {
      return this.configuracao.passo3.template === 'SUST_INDIRETA';
    },
    isDetalheAcaoTemplateServicosTerceiros() {
      return this.configuracao.passo3.template === 'SUST_SERVICO_TERCEIROS';
    },
    isDetalheAcaoTemplateComunicacao() {
      return this.configuracao.passo3.template === 'SUST_COMUNICACAO';
    },
    podeEditarApuracao() {
      return this.acao.statusApuracao === 'AGUARDANDO_APURACAO'
       && this.podeEditar && this.configuracao.passo3
       && this.configuracao.passo3.indPermitirAlteracaoApuracao;
    },
    indCarregado() {
      return this.tipoAcao && this.indConfiguracao;
    },
  },
  methods: {
    alteraValorAcao() {

    },
    carregarTipoAcao(idTipoAcao) {
      return this.tipoAcaoResource.buscarTipoAcaoComConfiguracao({ idTipoAcao })
        .then((res) => {
          this.tipoAcao = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    carregarConfiguracao(idTipoAcao) {
      const { idAcao } = this.acao;

      const promise = this.novo
        ? this.configuracaoResource.buscarConfigVigente({ idTipoAcao })
        : this.configuracaoResource.buscarConfigSnapshot({ idAcao });

      return promise.then((res) => {
        this.conf = new AcaoConfiguracao(res.data);
        this.indConfiguracao = true;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvar() {
      this.$refs.detalheAcao.salvarDetalhesApuracao();
    },
    getListaItens() {
      if (this.$refs.detalheAcao && this.$refs.detalheAcao.getServicos) {
        return this.$refs.detalheAcao.getServicos();
      }
      return [];
    },
  },
  mounted() {
    this.conf = { ...this.configuracao };
    const { idAcaoTipo } = this.acao;
    this.carregarTipoAcao(idAcaoTipo);
    this.carregarConfiguracao(idAcaoTipo);
  },
};
</script>
