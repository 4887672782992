<template>
  <div>
    <v-dialog v-model="exibeModalPagamento" max-width="1200px">
        <v-card>
          <v-card-title>
            <span class="headline" v-if="!pagamento.id">{{ $t('label.adicionar_item') }}</span>
            <span class="headline" v-else>{{ $t('label.editar_item') }}</span>
          </v-card-title>
          <v-form ref="form" lazy-validation autocomplete="off">
            <v-container grid-list-md fluid>
              <metadados-container-layout
                v-if="metadadosPagamentoManual != null"
                :metadados-entidade="metadadosPagamentoManual"
                :ordenacao-campos="ordenacaoCampos"
                :layout-class="layoutClass"
                :input-layout="inputLayout"
                :objeto="pagamento"
                :somenteLeitura="somenteLeitura || isVisualizacao"
                ref="container">
                <v-col cols="12" sm="4" slot="depois">
                  <v-select
                    v-model="pagamento.idsEvidencias"
                    :items="listaEvidencias"
                    :disabled="somenteLeitura || isVisualizacao"
                    :label="`${$tc('label.evidencia_apuracao', 1)} *`"
                    item-text="descricao"
                    item-value="id"
                    dense
                    multiple
                    :rules="[rules.requiredLista]">
                  </v-select>
                </v-col>
                <v-col cols="8" sm="4" slot="depois" v-if="!podeAlterarPagamento">
                  <input-money
                    :label="`${$tc('label.valor_acao', 1)}`"
                    :disabled="true"
                    v-model="acao.valorAcao">
                  </input-money>
                </v-col>
                <v-col cols="8" sm="4" slot="depois">
                  <input-money
                    :label="`${$tc('label.total_pagamento', 2)}`"
                    :disabled="true"
                    v-model="vlrTotalPagamentos">
                  </input-money>
                </v-col>
                <v-col cols="8" sm="4" slot="depois" v-if="!podeAlterarPagamento">
                  <input-money
                    :label="`${$tc('label.pendente_pagamento', 1)}`"
                    :disabled="true"
                    v-model="vlrPendentePagamento"
                    v-bind:class="{
                      'ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo': (vlrPendentePagamento < 0)
                    }">
                  </input-money>
                </v-col>
                <v-col cols="8" sm="4" slot="depois">
                  <input-money
                    :label="`${$tc('label.valor_pagamento', 1)} *`"
                    :rules="[rules.required, rules.valorPositivo, rules.valorMaiorQuePendente]"
                    :disabled="somenteLeitura || isVisualizacao"
                    v-model="pagamento.vlrPagamento">
                  </input-money>
                </v-col>
              </metadados-container-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secundary"
                @click="close">{{ somenteLeitura || isVisualizacao ? $t('label.voltar') : $t('label.cancelar') }}</v-btn>
              <v-btn
                v-if="!somenteLeitura && !isVisualizacao"
                color="primary"
                @click.native="openSaveDialog" >{{ $t('label.liquidar_pagamento') }}</v-btn>
              <v-btn
                v-if="!somenteLeitura && !isVisualizacao"
                color="primary"
                @click="salvarPagamentoManual(false)" >{{ !isEdicao ? $t('label.adicionar') : $t('label.salvar') }}</v-btn>
            </v-card-actions>
            <confirm
              ref="confirmDialog"
              :message="dialog.content"
              :persistent="true"
              @agree="salvarPagamentoManual(true)">
            </confirm>
          </v-form>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import InputMoney from '../../shared-components/inputs/InputMoney';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import { getMoney } from '../../common/functions/helpers';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';

export default {
  name: 'ApuracaoAcaoDetalhesPagamentoModal',
  components: {
    InputMoney,
    MetadadosContainerLayout,
    Confirm,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
    metadadosPagamentoManual: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    valorTotalPagamentos: {
      type: Number,
      default: 0,
    },
    valorPendentePagamento: {
      type: Number,
      default: 0,
    },
    acao: {
      type: Object,
      default: {
        vlrAcao: 0,
      },
    },
    podeAlterarPagamento: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    vlrTotalPagamentos: {
      get() {
        if (this.unicaConta) {
          return this.contaCorrente.vlrPagamento
            + (this.contaCorrente.vlrPlanejado - this.contaCorrente.vlrPendente);
        }
        return this.valorTotalPagamentos;
      },
      set(newValue) {
        this.valorTotal = newValue;
      },
    },
    vlrPendentePagamento: {
      get() {
        if (this.unicaConta) {
          return this.contaCorrente.vlrPendente - this.pagamento.vlrPagamento;
        }
        if (this.isEdicao) {
          return (this.valorPendentePagamento - this.pagamento.vlrPagamento)
            + this.valorOriginalPagamento;
        }
        return this.valorPendentePagamento - this.pagamento.vlrPagamento;
      },
      set(newValue) {
        this.valorPendente = newValue;
      },
    },
  },
  data() {
    return {
      resource: this.$api.apuracaoAcaoPagamento(this.$resource),

      isEdicao: false,
      isVisualizacao: false,
      exibeModalPagamento: false,
      listaEvidencias: [],
      valorTotal: 0,
      valorPendente: 0,
      valorOriginalPagamento: 0,
      valorOriginalPendente: 0,
      pagamento: {},
      ordenacaoCampos: [],
      contasCorrentes: [],
      contaCorrente: {},
      unicaConta: true,
      semContas: false,
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      dialog: {
        content: '',
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
        valorPositivo: (value) => ((!!value && value > 0) || (this.podeAlterarPagamento)) || this.$t('errors.valor.negativo'),
        valorMaiorQuePendente: (value) => ((!!value && value <= this.valorOriginalPendente) || (this.podeAlterarPagamento)) || this.$t('errors.valor.maior_que_pendente'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  methods: {
    getMoney,
    open(item, isVisualizacao = false) {
      if (item) {
        this.pagamento = { ...item };
        this.valorOriginalPagamento = this.pagamento.vlrPagamento;
        this.valorOriginalPendente = this.valorPendentePagamento + this.valorOriginalPagamento;
        this.isEdicao = true;
        this.consultarContasPagamento(this.pagamento.id);
      } else {
        this.valorOriginalPendente = this.valorPendentePagamento;
        this.resetaPagamento();
        this.consultarContasApuracao(this.acao.idApuracao);
      }
      this.carregarListaEvidencias();
      this.isVisualizacao = isVisualizacao;
      this.exibeModalPagamento = true;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    close() {
      this.isEdicao = false;
      this.exibeModalPagamento = false;
      this.resetaPagamento();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.deseja_liquidar_pagamento');
      this.$refs.confirmDialog.open();
    },
    consultarContasPagamento(id) {
      this.resource.buscaContasVinculadas({ idApuracaoPagamento: id })
        .then(this.preencherContas, (err) => {
          this.$error(this, err);
        });
    },
    consultarContasApuracao(id) {
      this.resource.buscaContasVinculadasApuracao({ idApuracao: id })
        .then(this.preencherContas, (err) => {
          this.$error(this, err);
        });
    },
    preencherContas(res) {
      this.contasCorrentes = res.data;
      if (this.contasCorrentes && this.contasCorrentes.length > 0) {
        this.semContas = false;
        if (this.contasCorrentes.length === 1) {
          this.unicaConta = true;
          const [conta] = this.contasCorrentes;
          this.contaCorrente = conta;
        } else {
          this.unicaConta = false;
        }
      } else {
        this.semContas = true;
      }
    },
    carregarListaEvidencias() {
      this.resource.buscarEvidencias({ idApuracao: this.acao.idApuracao })
        .then((res) => {
          this.listaEvidencias = res.data;
        }, (err) => {
          this.$error(this, err);
          this.$toast(this.$t(err));
        });
    },
    resetaPagamento() {
      this.pagamento = {
        vlrPagamento: 0,
        statusPagamento: 'EM_CADASTRO',
        idsEvidencias: [],
      };
    },
    salvarPagamentoManual(concluir = false) {
      if (!this.$refs.form.validate()) return;
      if (concluir && this.podeAlterarPagamento && this.isPagamentoMaiorOriginalPendente() && !this.isSaldoDisponivelConta()) {
        this.$toast(this.getMessageContaSemSaldo());
        return;
      }
      this.salvaAtualiza(concluir);
    },
    salvaAtualiza(concluir) {
      this.pagamento = {
        ...this.acao,
        ...this.pagamento,
        desAtributos: {},
      };
      const camposDinamicos = this.$refs.container.getValoresCamposDinamicos();

      this.ordenacaoCampos.forEach((c) => {
        this.pagamento.desAtributos[c] = camposDinamicos[c] || null;
      });

      if (concluir) {
        this.pagamento.statusPagamento = 'LIQUIDADO';
      }

      this.preencherContasSalvar(this.pagamento);

      const promise = this.isEdicao
        ? this.resource.atualizar(this.pagamento)
        : this.resource.salvar(this.pagamento);

      promise
        .then(() => {
          this.isEdicao = false;
          this.exibeModalPagamento = false;
          this.resetaPagamento();
          this.$emit('ATUALIZAR_TABELA');
        }, (err) => {
          this.$error(this, err);
          this.$toast(this.$t(err));
        });
    },
    isSaldoDisponivelConta() {
      const saldo = this.getSaldoConta();
      return saldo > this.getValorNovaReserva();
    },
    getValorAdicionadoApuracao() {
      return this.acao.vlrConfApuracao - this.acao.valorAcao;
    },
    getValorNovaReserva() {
      if (this.valorOriginalPendente < 0) {
        return this.pagamento.vlrPagamento;
      }
      return this.pagamento.vlrPagamento - this.valorOriginalPendente;
    },
    isPagamentoMaiorOriginalPendente() {
      return this.pagamento.vlrPagamento > this.valorOriginalPendente;
    },
    getMessageContaSemSaldo() {
      const message = this.$t('message.apuracao_pagamento_liquidado_saldo_insuficiente', {
        vlrSaldoDisponivel: this.getMoney(this.getSaldoConta()),
      });
      return message;
    },
    getSaldoConta() {
      const [conta] = this.contasCorrentes;
      return conta.saldo;
    },
    preencherContasSalvar() {
      if (this.semContas) return;
      this.pagamento.contasCorrentes = [];
      if (this.unicaConta) {
        this.contaCorrente.vlrPagamento = this.pagamento.vlrPagamento;
        this.pagamento.contasCorrentes.push(this.montarContaSalvar(this.contaCorrente));
      } else {
        this.contasCorrentes.forEach((cc) => {
          this.pagamento.contasCorrentes.push(this.montarContaSalvar(cc));
        });
      }
    },
    montarContaSalvar(contaCorrente) {
      return {
        idContaCorrente: contaCorrente.idContaCorrente,
        vlrPagamento: contaCorrente.vlrPagamento,
      };
    },
  },
  mounted() {
    this.ordenacaoCampos = this.configuracao.passo7.listaLiquidacaoManual
      .sort((a, b) => a.ordenacao < b.ordenacao).map((c) => c.label);
  },
};
</script>
<style>
.ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo,
.ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo input.v-money {
  color: #ff0000ba;
}
</style>
