import ApuracaoDetalhesAcao from './ApuracaoDetalhesAcao';

export default {
  componentes: {
    ApuracaoDetalhesAcao,
  },
  paineisHabilitados(ref, configuracao, acao) { // eslint-disable-line
    return [
      {
        nome: 'apuracaoDetalhesAcao',
        habilitado: this.exibeSecaoApuracao(configuracao),
        componente: 'apuracao-detalhes-acao',
        configuracao,
        acao,
      },

    ];
  },
  exibeSecaoApuracao(configuracao) {
    return configuracao.passo3 && configuracao.passo3.indExibirSecaoApuracao;
  },
};
