<template>
  <v-expansion-panel>
    <v-form ref="form" lazy-validation>
      <v-expansion-panel-header class="headline">{{ $tc('label.complemento_informacao', 1)}}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <metadados-container-layout
          :metadados-entidade="metadados"
          :ordenacao-campos="ordenacaoCampos"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :somenteLeitura="somenteLeitura"
          :objeto="valoresComplementares"
          ref="container">
        </metadados-container-layout>
      </v-expansion-panel-content>
    </v-form>
  </v-expansion-panel>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { copyObject } from '../../common/functions/helpers';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';

export default {
  name: 'ApuracaoAcaoComplementoInformacoes',
  props: {
    configuracao: {
      type: Object,
      required: true,
    },
    acao: {
      type: Object,
      default: {},
    },
    metadados: {
      type: Object,
      default: {},
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('metadados', [
      'getApuracaoMetadado',
    ]),
    getCampos() {
      return this.configuracao.passo3.listaDinamica
        .filter((campo) => campo.etapasFluxo && campo.etapasFluxo.length > 0
          && campo.etapasFluxo.indexOf('APURACAO') > -1);
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),

      ordenacaoCampos: [],
      valoresComplementares: {},
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
    };
  },
  methods: {
    ...mapActions('metadados', [
      'setApuracaoMetadado',
    ]),
    setMetadados() {
      this.valoresComplementares = this.acao.complementoInformacoes ? JSON.parse(this.acao.complementoInformacoes.value) : {};
      const camposDinamicos = {};
      if (this.metadados) {
        const campos = copyObject(this.metadados.mapaCamposDinamicos);
        this.getCampos.forEach((campo) => {
          if (campos[campo.label] && !campo.indHabilitaAprovacaoApuracao) {
            camposDinamicos[campo.label] = campos[campo.label];
          }
        });
        this.metadados.mapaCamposDinamicos = camposDinamicos;
      }
    },
    validarCamposComplementares() {
      return this.$refs.form.validate();
    },
    salvar() {
      if (this.$refs.container) {
        const param = {
          id: this.acao.idApuracao,
        };
        param.complementoInformacoes = { ...this.$refs.container.getValoresCamposDinamicos() };
        this.apuracaoAcaoResource.atualizarComplementoInformacoes(param)
          .then()
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
  },
  mounted() {
    this.setMetadados();
    this.ordenacaoCampos = this.configuracao.passo7.listaComplementoInformacoes
      .sort((a, b) => a.ordenacao < b.ordenacao).map((c) => c.label);
  },
};
</script>
