var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$tc('title.pagamento', 1))+" ")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.cabecalho,"items":_vm.pagamentos,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 20, 30],
      },"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_c('td',[((_vm.canAddPagamentoManual
                     && _vm.canEdit
                      && !_vm.somenteLeitura
                       && _vm.isPagamentoEmCadastro(item)) ||
                      (_vm.canAccessPage
                       || (_vm.canAddPagamentoManual
                        && _vm.canEdit
                         && !_vm.isPagamentoEmCadastro(item))))?_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                         var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[(_vm.canAddPagamentoManual
                   && _vm.canEdit
                    && !_vm.somenteLeitura
                     && _vm.isPagamentoEmCadastro(item))?_c('v-list-item',{on:{"click":function($event){return _vm.editar(item, false)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.editar', 1)))])],1):(_vm.canAccessPage)?_c('v-list-item',{on:{"click":function($event){return _vm.editar(item, true)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("visibility")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.visualizar', 1)))])],1):_vm._e(),(_vm.canAddPagamentoManual
                   && _vm.canEdit
                    && !_vm.somenteLeitura
                     && _vm.isPagamentoEmCadastro(item))?_c('v-list-item',{on:{"click":function($event){return _vm.excluir(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.excluir', 1)))])],1):_vm._e()],1)],1):_vm._e()],1),_vm._l((_vm.camposDinamicosCabecalho),function(campo){return _c('td',{key:((item.id) + "-" + (campo.labelCampo))},[(campo.labelCampo === 'evidencia')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                     var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("truncate")(item.evidencias,50))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.evidencias)+" ")]):(campo.labelCampo === 'status_pagamento')?_c('span',[_vm._v(" "+_vm._s(_vm.$tc(("status_entidade_pagamento." + (item.statusPagamento.toLowerCase())), 1))+" ")]):(campo.labelCampo === 'valor_pagamento')?_c('span',[_vm._v(_vm._s(_vm.getMoney(item.vlrPagamento)))]):(campo.labelCampo === 'valor_acao')?_c('span',[_vm._v(_vm._s(_vm.getMoney(item.vlrAcao)))]):(campo.tipoCampo === 'DATA')?_c('span',[_vm._v(_vm._s(_vm.formatDatePattern(item[campo.labelCampo], 'YYYY-MM-DD')))]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                     var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("truncate")(item[campo.labelCampo],50)))])]}}],null,true)},[_vm._v(" "+_vm._s(item[campo.labelCampo])+" ")])],1)})],2)]}}])}),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"end"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.canEdit && _vm.exibeBotaoAddPagamento && !_vm.somenteLeitura),expression:"canEdit && exibeBotaoAddPagamento && !somenteLeitura"}],staticClass:"v-btn__fab",attrs:{"fab":"","bottom":"","right":"","color":"primary"},nativeOn:{"click":function($event){return _vm.abrirModalPagamento()}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)],1),_c('confirm',{ref:"confirmDialog",attrs:{"persistent":true}}),(_vm.carregouMetadados)?_c('apuracao-acao-detalhes-pagamento-modal',{ref:"modalFormPagamento",attrs:{"acao":_vm.acao,"podeAlterarPagamento":_vm.podeAlterarPagamento,"somente-leitura":_vm.somenteLeitura,"valor-total-pagamentos":_vm.valorTotalPagamentos,"valor-pendente-pagamento":_vm.valorPendentePagamento,"metadados-pagamento-manual":_vm.metadados,"configuracao":_vm.configuracao},on:{"ATUALIZAR_TABELA":_vm.atualizarTodosPagamentos}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }