<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      {{ $tc('title.pagamento', 1)}}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        :headers="cabecalho"
        :items="pagamentos"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 20, 30],
        }"
        dense>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-menu offset-y :close-on-content-click="false" eager
                      v-if="(canAddPagamentoManual
                       && canEdit
                        && !somenteLeitura
                         && isPagamentoEmCadastro(item)) ||
                        (canAccessPage
                         || (canAddPagamentoManual
                          && canEdit
                           && !isPagamentoEmCadastro(item)))">
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="py-0">
                  <v-list-item
                    v-if="canAddPagamentoManual
                     && canEdit
                      && !somenteLeitura
                       && isPagamentoEmCadastro(item)"
                    @click="editar(item, false)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-else-if="canAccessPage"
                    @click="editar(item, true)">
                    <v-list-item-action>
                      <v-icon>visibility</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.visualizar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canAddPagamentoManual
                     && canEdit
                      && !somenteLeitura
                       && isPagamentoEmCadastro(item)"
                    @click="excluir(item)">
                    <v-list-item-action>
                      <v-icon>delete</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.excluir', 1) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td v-for="campo in camposDinamicosCabecalho" :key="`${item.id}-${campo.labelCampo}`">
              <v-tooltip top v-if="campo.labelCampo === 'evidencia'">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    {{ item.evidencias | truncate(50) }}
                  </span>
                </template>
                {{ item.evidencias }}
              </v-tooltip>
              <span v-else-if="campo.labelCampo === 'status_pagamento'">
                {{ $tc(`status_entidade_pagamento.${item.statusPagamento.toLowerCase()}`, 1) }}
              </span>
              <span v-else-if="campo.labelCampo === 'valor_pagamento'">{{ getMoney(item.vlrPagamento) }}</span>
              <span v-else-if="campo.labelCampo === 'valor_acao'">{{ getMoney(item.vlrAcao) }}</span>
              <span v-else-if="campo.tipoCampo === 'DATA'">{{ formatDatePattern(item[campo.labelCampo], 'YYYY-MM-DD') }}</span>
              <v-tooltip top v-else>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ item[campo.labelCampo] | truncate(50) }}</span>
                </template>
                {{ item[campo.labelCampo] }}
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-row>
        <v-col style="text-align: end;">
          <v-btn fab
            bottom right
            class="v-btn__fab"
            color="primary"
            @click.native="abrirModalPagamento()"
            v-show="canEdit && exibeBotaoAddPagamento && !somenteLeitura">
            <v-icon>add</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>
    <apuracao-acao-detalhes-pagamento-modal
      ref="modalFormPagamento"
      v-if="carregouMetadados"
      :acao="acao"
      :podeAlterarPagamento="podeAlterarPagamento"
      :somente-leitura="somenteLeitura"
      :valor-total-pagamentos="valorTotalPagamentos"
      :valor-pendente-pagamento="valorPendentePagamento"
      :metadados-pagamento-manual="metadados"
      :configuracao="configuracao"
      @ATUALIZAR_TABELA="atualizarTodosPagamentos"/>
  </v-expansion-panel>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  formatDatePattern,
} from '../../common/functions/date-utils';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { getMoney, copyObject } from '../../common/functions/helpers';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import ApuracaoAcaoDetalhesPagamentoModal from './ApuracaoAcaoDetalhesPagamentoModal';

export default {
  name: 'ApuracaoAcaoDetalhesPagamento',
  props: {
    acao: {
      type: Object,
      default: {},
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    metadados: {
      type: Object,
      default: {},
    },
    configuracao: {
      type: Object,
      required: true,
    },
    podeAlterarPagamento: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Confirm,
    ApuracaoAcaoDetalhesPagamentoModal,
  },
  data() {
    return {
      resource: this.$api.apuracaoAcaoPagamento(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),

      pagamentos: [],
      carregouMetadados: false,
      cabecalho: [],
      camposDinamicosCabecalho: [],
      camposPadroes: {
        evidencia: {
          text: this.$tc('label.evidencia', 1), width: '15%', value: 'evidencias', sortable: false,
        },
        status_pagamento: {
          text: this.$tc('label.status_pagamento', 1), width: '15%', value: 'status_pagamento', sortable: false,
        },
        valor_acao: {
          text: this.$tc('label.valor_acao', 1), width: '10%', value: 'vlr_acao', sortable: false,
        },
        valor_pagamento: {
          text: this.$tc('label.valor_pagamento', 1), width: '10%', value: 'vlr_pagamento', sortable: false,
        },
      },
    };
  },
  filters: {
    truncate(value, limit) {
      if (value && value.length > limit) {
        value = value.substring(0, limit).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canAccessPage',
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAddPagamentoManual() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'PAGAMENTO_MANUAL_CRUD').length;
    },
    isApuracaoAguardandoApuracao() {
      return this.acao.statusApuracao === 'AGUARDANDO_APURACAO';
    },
    exibeBotaoAddPagamento() {
      return this.canAddPagamentoManual && this.isApuracaoAguardandoApuracao;
    },
    valorTotalPagamentos() {
      return this.pagamentos
        .map((item) => item.vlrPagamento)
        .reduce((prev, next) => prev + next, 0);
    },
    valorPendentePagamento() {
      return Number((this.acao.valorAcao - this.valorTotalPagamentos).toFixed(2));
    },
    possuiPagamentoAprovado() {
      return this.pagamentos.filter((el) => el.statusPagamento !== 'EM_CADASTRO').length > 0;
    },
    getCampos() {
      return this.configuracao.passo6.liquidacao.camposDinamicosPagamentoManual;
    },
    getOrdemCampos() {
      const camposGrid = (this.configuracao.passo8.listaLiquidacaoManual || [])
        .filter((c) => c.habilitaGrid)
        .map((c) => c.label);
      return (this.configuracao.passo7.listaLiquidacaoManual || [])
        .filter((c) => camposGrid.includes(c.label));
    },
  },
  methods: {
    getMoney,
    formatDatePattern,
    setMetadados() {
      const camposDinamicos = {};
      if (this.metadados) {
        const campos = copyObject(this.metadados.mapaCamposDinamicos);
        if (this.getCampos) {
          this.getCampos.forEach((campo) => {
            if (campos[campo.label]) {
              camposDinamicos[campo.label] = campos[campo.label];
            }
          });
        }
        this.metadados.mapaCamposDinamicos = camposDinamicos;
        this.metadados.mapaEntidades = {};
        this.carregouMetadados = true;
        this.montarCabecalho();
      }
    },
    montarCabecalho() {
      this.cabecalho = [{
        text: this.$tc('label.acoes', 2),
        value: 'acoes',
        sortable: false,
        width: '10%',
      }];
      this.camposDinamicosCabecalho = [];
      if (this.metadados.mapaCamposDinamicos) {
        this.getOrdemCampos.forEach((c) => {
          const campo = this.metadados.mapaCamposDinamicos[c.label];
          if (campo) {
            this.camposDinamicosCabecalho.push(campo);
            this.cabecalho.push({
              text: campo.nomCampo,
              value: campo.labelCampo,
              sortable: false,
            });
          } else if (this.camposPadroes[c.label]) {
            this.camposDinamicosCabecalho.push({ labelCampo: c.label });
            this.cabecalho.push(this.camposPadroes[c.label]);
          }
        });
      }
    },
    atualizarTodosPagamentos() {
      const params = {
        idAcao: this.acao.idAcao,
        idApuracao: this.acao.idApuracao,
        page: 1,
        size: 999,
      };
      this.resource.buscar(params)
        .then((res) => {
          const response = res.data.resposta;
          if (response && response.length > 0) {
            this.pagamentos = res.data.resposta.map((pgt) => this.montarObjeto(pgt));
          }
          this.$emit('PagamentoManual__alterado', this.possuiPagamentoAprovado);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    montarObjeto(pag) {
      return {
        idAcao: pag.id_acao,
        idApuracao: pag.id_apuracao,
        idPagamento: pag.id_pagamento,
        vlrPagamento: pag.vlr_pagamento,
        statusPagamento: pag.status_pagamento,
        vlrAcao: pag.vlr_acao,
        idsEvidencias: pag.ids_evidencias ? pag.ids_evidencias.split(',').map(Number) : '',
        ...pag.des_atributos,
        ...pag,
      };
    },
    excluir(item) {
      this.resource.deletar({ idPagamentoManual: item.id })
        .then(() => {
          this.atualizarTodosPagamentos();
        }, (err) => {
          this.$error(this, err);
          this.$toast(this.$t(err));
        });
    },
    editar(item, isVisualizacao = false) {
      this.$refs.modalFormPagamento.open(item, isVisualizacao);
    },
    isPagamentoEmCadastro(pagamento) {
      return pagamento.statusPagamento === 'EM_CADASTRO';
    },
    abrirModalPagamento() {
      this.$refs.modalFormPagamento.open();
    },
  },
  mounted() {
    this.setMetadados();
    this.atualizarTodosPagamentos();
  },
};
</script>
